@import "@/assets/style/_index.scss";

































































































.prepaid-card {
  padding: $padding-big * 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  > .img-obj {
    flex: 0 0 auto;
    width: 80%;
    > .inner {
      width: 100%;
      height: 0;
      padding-top: 45%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      box-shadow: 0 2px 10px 2px rgba(60, 60, 60, 0.2);
      border-radius: 0.1rem;
    }
  }
  > .body {
    flex: 1 1 1%;
    line-height: 1.5;
    text-align: center;
    margin-top: $margin;
    > .label {
      font-size: 0.3rem;
      font-weight: bold;
    }
    > .desc {
      color: $gray6;
      @include text-line(2);
    }
  }
  > .worth {
    text-align: center;
    margin-top: $margin-big;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    > .value {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 0 $margin;
      > .label {
        line-height: 1.2;
        font-size: 0.4rem;
        font-weight: bold;
        color: $color-main;
      }
      > .badge {
        order: 0;
        margin-right: $margin-small;
      }
      > .price {
        order: 1;
      }
    }
  }
}
