@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.pocket-money {
  min-height: 2.8rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
  text-align: center;
  padding: 0.6rem 0.2rem;
  background-color: #fff;
}
.pocket-money > .icon > img {
  width: 30%;
}
.pocket-money > .label {
  font-size: 0.3rem;
}
.pocket-money > .money {
  font-size: 0.6rem;
  font-weight: bold;
  color: #000;
}
.pocket-money > .money:before {
  content: "$";
  font-size: inherit;
}
.pocket-money > .btn.prepaid {
  padding: 0.2rem;
  border-radius: 0.1rem;
  margin-top: 0.3rem;
  font-size: 0.3rem;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.prepaid-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.visa-icon[data-v-7e360ea6] {
  width: 0.8rem;
  padding: 0.2rem 0;
}
.visa-icon > img[data-v-7e360ea6] {
  width: 100%;
  height: auto;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.prepaid-card {
  padding: 0.6rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #fff;
}
.prepaid-card > .img-obj {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 80%;
}
.prepaid-card > .img-obj > .inner {
  width: 100%;
  height: 0;
  padding-top: 45%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 0.267vw 1.333vw 0.267vw rgba(60, 60, 60, 0.2);
  border-radius: 0.1rem;
}
.prepaid-card > .body {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
  line-height: 1.5;
  text-align: center;
  margin-top: 0.2rem;
}
.prepaid-card > .body > .label {
  font-size: 0.3rem;
  font-weight: bold;
}
.prepaid-card > .body > .desc {
  color: #868e96;
  overflow: hidden;
  display: -webkit-box;
  white-space: normal;
  word-break: break-all;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.prepaid-card > .worth {
  text-align: center;
  margin-top: 0.3rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.prepaid-card > .worth > .value {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 0 0.2rem;
}
.prepaid-card > .worth > .value > .label {
  line-height: 1.2;
  font-size: 0.4rem;
  font-weight: bold;
  color: #e52779;
  color: var(--color-main, #e52779);
}
.prepaid-card > .worth > .value > .badge {
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
          order: 0;
  margin-right: 0.1rem;
}
.prepaid-card > .worth > .value > .price {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
          order: 1;
}

/*# sourceMappingURL=pocketMoney.47aff29b.css.map*/